.nav-search{
	display: none;
	position: absolute;
	content: '';
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	@nest &__inp{
		padding-left: 5px;
		min-height: 40px;
	}
}