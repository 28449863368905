.header{
	padding: 30px 0 0;
	background: #fff;
	@media (max-width: 991px){
		text-align: center;
	}
	@nest & .container{
		padding-bottom: 25px;
		border-bottom: 1px solid #f4f4f4;
	}
	@nest &__logo{
		font-size: 26px;
		color: #000;
		font-weight: 500;
		padding-left: 100px;
		position: relative;
		text-align: left;
		letter-spacing: 1.3px;
		text-decoration: none;
		display: inline-block;
		@nest &-desc{
			font-size: 18px;
			font-weight: 300;
			letter-spacing: .9px;
			display: block;
			line-height: 18px;
		}
		@nest &:after{
			position: absolute;
			content: '';
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			background: resolve('logo.png');
			width: width('logo.png');
			height: height('logo.png');
			transition: opacity 300ms;
		}
		@nest &:hover{
			@nest &:after{
				opacity: .7;
			}
		}
	}
	@nest &__center{
		text-align: center;
		@media (max-width: 991px){
			margin: 20px 0;
		}
	}
	@nest &__adress{
		font-size: 15px;
		color: #000;
		font-weight: 300;
		display: block;
		line-height: 26px;
		letter-spacing: .75px;
		@nest &-bold{
			font-weight: 500;
		}
	}
	@nest &__right{
		text-align: right;
		@media (max-width: 1199px){
			text-align: center;
		}
	}
	@nest &__links{
		display: inline-block;
		@media (max-width: 1199px){
			display: block;
		}
	}
	@nest &__tel{
		display: block;
		font-size: 20px;
		color: #000;
		font-weight: 500;
		letter-spacing: 1px;
		text-decoration: none;
		line-height: normal;
		@media (max-width: 1199px){
			margin: 0 5px;
			display: inline-block;
		}
	}
	@nest &__call{
		font-size: 15px;
		color: #000;
		font-weight: 300;
		display: inline-block;
		text-decoration: none;
		@nest &:hover{
			text-decoration: underline;
		}
		@media (max-width: 1199px){
			margin: 5px 0;
		}
	}
	@nest &__btn{
		font-size: 18px;
		color: #fff;
		float: right;
		width: 170px;
		height: 65px;
		outline: none;
		margin-left: 10px;
		line-height: 65px;
		border-radius: 5px;
		text-align: center;
		text-decoration: none;
		background-image: -moz-linear-gradient( 45deg, rgb(81,11,212) 0%, rgb(103,39,223) 100%);
		background-image: -webkit-linear-gradient( 45deg, rgb(81,11,212) 0%, rgb(103,39,223) 100%);
		background-image: -ms-linear-gradient( 45deg, rgb(81,11,212) 0%, rgb(103,39,223) 100%);
		@nest &:hover{
			background-image: -moz-linear-gradient( 45deg, rgb(62,58,157) 0%, rgb(56,7,146) 100%);
			background-image: -webkit-linear-gradient( 45deg, rgb(62,58,157) 0%, rgb(56,7,146) 100%);
			background-image: -ms-linear-gradient( 45deg, rgb(62,58,157) 0%, rgb(56,7,146) 100%);
		}
		@media (max-width: 1199px){
			float: none;
			margin-left: 0;
			display: inline-block;
		}
	}
}