.modal-form{
	background: #fff;
	padding: 15px;
	text-align: center;
	@nest &__inp{
		width: 100%;
		min-height: 40px;
		padding-left: 10px;
		color: #ccc;
		font-size: 15px;
		margin-bottom: 10px;
		@nest &::placeholder{
			color: #ccc;
			font-size: 15px;
			font-weight: 300;
		}
	}
	@nest & .header__btn--mod{
		float: none;
		border: 0;
		height: 40px;
		line-height: 40px;
	}
}