.services-block{
	width: 250px;
	height: 250px;
	margin: 0 10px;
	display: inline-block;
	position: relative;
	border-radius: 12px;
	overflow: hidden;
	margin-bottom: 20px;
	box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.19);
	@media (max-width: 575px){
		display: block;
		margin: 0 auto 30px;
	}
	@nest &__link{
		position: absolute;
		content: '';
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		font-size: 18px;
		color: #fff;
		font-weight: 300;
		min-height: 68px;
		opacity: .8;
		padding: 10px 45px 0 15px;
		text-decoration: none;
		letter-spacing: .9px;
		border-bottom-right-radius: 12px;
		border-bottom-left-radius: 12px;
		background: color(#510bd4 alpha(80%));
		transition: background 300ms;
		background: -moz-linear-gradient( 45deg, rgb(81,11,212) 0%, rgb(103,39,223) 100%);
		background: -webkit-linear-gradient( 45deg, rgb(81,11,212) 50%, rgb(103,39,223) 100%);
		background: -ms-linear-gradient( 45deg, rgb(81,11,212) 0%, rgb(103,39,223) 100%);
		@nest &:after{
			position: absolute;
			content: '';
			right: 20px;
			top: 50%;
			opacity: 0;
			transform: translateY(-50%);
			background: resolve('serv.png');
			width: width('serv.png');
			height: height('serv.png');
			transition: opacity 300ms;
		}
		@nest &:hover{
			background: -moz-linear-gradient( 65deg, rgb(157,104,9) 0%, rgb(208,147,1) 100%);
			background: -webkit-linear-gradient( 65deg, rgb(157,104,9) 0%, rgb(208,147,1) 100%);
			background: -ms-linear-gradient( 65deg, rgb(157,104,9) 0%, rgb(208,147,1) 100%);
			@nest &:after{
				opacity: 1;
			}
		}
	}
}