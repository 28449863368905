.tabs{
	@media (max-width: 1199px){
		text-align: center;
	}
	@nest &-list{
		padding: 0;
		margin: 0 0 40px;
		list-style: none;
		@nest &__item{
			padding-right: 30px;
			display: inline-block;
			position: relative;
			@nest &:after{
				position: absolute;
				content: '';
				right: 13px;
				top: 50%;
				width: 1px;
				height: 11px;
				background: #605f5f;
				transform: translateY(-50%);
			}
			@nest &:last-child{
				@nest &:after{
					display: none;
				}
			}
			@nest & button{
				border: 0;
				outline: none;
				padding: 0;
				font-size: 15px;
				color: #605f5f;
				font-weight: 300;
				background: transparent;
			}
			@nest &_active{
				@nest & button{
					font-weight: 500;
					color: #5431b1;
					text-decoration: underline;
					letter-spacing: 1.125px;
				}
			}
		}
	}
	@nest &__content{
		margin: 0 -16px;
		font-size: 0;
		@media (max-width: 1199px){
			margin: 0;
		}
	}
	@nest &__image{
		width: 185px;
		margin: 0 10px 10px;
		display: inline-block;
		vertical-align: top;
		@nest &--lg{
			width: 100%;
			max-width: 362px;
			@media (max-width: 512px){
				max-width: 270px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}