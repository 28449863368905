.nav-list{
	padding: 0;
	margin: 0;
	list-style: none;
	@media (max-width: 767px){
		margin: 10px 0 0;
		text-align: center;
	}
	@nest &--footer{
		display: block !important;
		@media (max-width: 991px){
			margin-top: 35px;
		}
		@nest & .nav-list__item{
			padding-right: 27px;
			@nest &:after{
				right: 10px;
			}
			@media (max-width: 1199px){
				padding-right: 10px;
			}
			@media (max-width: 767px){
				padding-right: 0;
			}
		}
		@nest & .nav-list__link{
			font-size: 15px;
			font-weight: 400;
			@media (max-width: 1199px){
				font-size: 14px;
			}
		}
	}
	@nest &__item{
		padding: 0 40px 0 0;
		display: inline-block;
		position: relative;
		@nest &:after{
			position: absolute;
			content: '';
			right: 16px;
			width: 2px;
			height: 12px;
			top: 50%;
			background: #7f7f7f;
			transform: translateY(-50%);
			@media (max-width: 1199px){
				display: none;
			}
		}
		@nest &:last-child{
			@nest &:after{
				display: none;
			}
		}
		@media (max-width: 1199px){
			padding-right: 25px;
		}
		@media (max-width: 991px){
			padding-right: 15px;
		}
		@media (max-width: 767px){
			display: block;
			padding: 0;
			margin-bottom: 10px;
		}
	}
	@nest &__link{
		font-size: 17px;
		color: #000;
		font-weight: 300;
		text-decoration: none;
		@nest &:hover{
			text-decoration: underline;
		}
		@media (max-width: 991px){
			font-size: 15px;
		}
	}
}