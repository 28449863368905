.news-block{
	max-width: 250px;
	background: #f6f6f6;
	border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  margin: 0 10px;
  text-align: left;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.19);
  @media (max-width: 1199px){
    margin-bottom: 16px;
  }
  @nest &--lg{
    max-width: 335px;
    background: #fff;
    min-height: 430px;
    @nest & .news-block__content{
      padding: 25px 15px 25px 20px;
    }
    @nest & .news-block__image{
      height: 200px;
      @media (max-width: 1199px){
        height: auto;
      }
    }
    @nest & .news-block__title{
      font-size: 20px;
    }
  }
  @nest &__image{
    height: 135px;
    width: 100%;
  }
  @nest &__content{
  	padding: 10px 10px 20px 10px;
  }
  @nest &__date{
  	display: block;
  	font-size: 10px;
  	color: #777575;
  	font-weight: 300;
  	margin-bottom: 15px;
  }
  @nest &__title{
  	font-size: 16px;
  	color: #1c1b1b;
  	display: block;
    font-weight: 500;
  	letter-spacing: .4px;
  	margin-bottom: 15px;
  }
  @nest &__text{
  	font-size: 14px;
  	color: #a5a5a5;
  	margin: 0;
  	font-weight: 300;
  }
  @nest &__link{
  	font-size: 14px;
  	color: #6727df;
  	text-decoration: none;
  	margin: 15px 0 0 10px;
  	display: inline-block;
  	@nest &:hover{
  		text-decoration: underline;
  	}
  }
}