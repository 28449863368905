.news{
	background: #ececec;
	padding: 40px 0 50px;
	@nest &__wrap{
		font-size: 0;
		margin: 0 -16px;
		@media (max-width: 991px){
			margin: 0;
			text-align: center;
		}
	}
	@nest &__left{
		width: 68%;
		margin-right: 4%;
		display: inline-block;
		vertical-align: top;
		@media (max-width: 991px){
			width: 100%;
			margin: 0;
		}
	}
	@nest &__right{
		width: 28%;
		display: inline-block;
		vertical-align: top;
		@media (max-width: 991px){
			width: 100%;
			margin-top: 20px;
		}
	}
	@nest &__bot{
		margin: 20px 0 0;
	}
	@nest &__link{
		float: right;
		font-size: 16px;
		color: #6625de;
		width: 165px;
		height: 48px;
		line-height: 48px;
		margin-top: 20px;
		text-align: center;
		text-decoration: none;
		border-radius: 4px;
		border: 1px solid #6727df;
		transition: color 300ms, background 300ms;
		@nest &:hover{
			color: #fff;
			background: #6727df;
		}
		@media (max-width: 767px){
			float: none;
			display: inline-block;
		}
	}
}