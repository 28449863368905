.gallery{
	margin: 40px 0 0;
	padding-bottom: 35px;
	@nest &__btn{
		width: 145px;
		height: 40px;
		font-weight: 300;
		outline: none;
		display: block;
		margin: 20px auto 0;
		border-radius: 4px;
		border: 1px solid #b8b8b8;
		background-color: transparent;
		color: color(#121212 alpha(62%));
		transition: color 300ms, background 300ms;
		@nest &:hover{
			background: #b8b8b8;
			color: #fff;
		}
	}
}