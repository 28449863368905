@import "normalize-css/normalize.css";
@import "slick-carousel/slick/slick.css";
@import "fancybox/source/jquery.fancybox.css";
/*! main */

html, body{
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	background: #f4f4f4;
}
.slider{
  margin-bottom: 30px;
}
.o-wrapper {
  height: 100%;
  min-width: 320px
}
.o-content {
  min-height: 100%;
  padding-bottom: 205px
}
@font-face {
  font-family: 'PFAgoraSansPro';
  src: url("../fonts/PFAgoraSansPro.eot");
  src: url("../fonts/PFAgoraSansPro.eot?#iefix") format('embedded-opentype'),
       url("../fonts/PFAgoraSansPro.woff") format('woff'),
       url("../fonts/PFAgoraSansPro.ttf") format('truetype'),
       url("../fonts/PFAgoraSansPro.svg") format('svg');
  font-weight: 400
}
.o-container:after {
  content: '';
  display: block;
  clear: both
}
.o-container {
  padding: 0 15px;
  margin: 0 auto
}
@media (min-width:768px) {
  .o-container {
    max-width: 740px
  }
}
@media (min-width:992px) {
  .o-container {
    max-width: 960px
  }
}
@media (min-width:1240px) {
  .o-container {
    max-width: 1200px
  }
}
@media (max-width:480px) {
  .o-container {
    padding: 0 10px
  }
}
.o-layout__row:after {
  content: '';
  display: block;
  clear: both
}
.o-layout__row {
  margin: 0 -15px
}
@media (max-width:480px) {
  .o-layout__row {
    margin: 0 -10px
  }
}
.o-layout__col {
  position: relative;
  min-height: 1px;
  padding: 0 10px;
  float: left
}
.o-layout__col_xs_1 {
  width: 8.33333%
}
.o-layout__col_xs-pull_1 {
  right: 8.33333%
}
.o-layout__col_xs-push_1 {
  left: 8.33333%
}
.o-layout__col_xs-offset_1 {
  margin-left: 8.33333%
}
.o-layout__col_xs_2 {
  width: 16.66667%
}
.o-layout__col_xs-pull_2 {
  right: 16.66667%
}
.o-layout__col_xs-push_2 {
  left: 16.66667%
}
.o-layout__col_xs-offset_2 {
  margin-left: 16.66667%
}
.o-layout__col_xs_3 {
  width: 25%
}
.o-layout__col_xs-pull_3 {
  right: 25%
}
.o-layout__col_xs-push_3 {
  left: 25%
}
.o-layout__col_xs-offset_3 {
  margin-left: 25%
}
.o-layout__col_xs_4 {
  width: 33.33333%
}
.o-layout__col_xs-pull_4 {
  right: 33.33333%
}
.o-layout__col_xs-push_4 {
  left: 33.33333%
}
.o-layout__col_xs-offset_4 {
  margin-left: 33.33333%
}
.o-layout__col_xs_5 {
  width: 41.66667%
}
.o-layout__col_xs-pull_5 {
  right: 41.66667%
}
.o-layout__col_xs-push_5 {
  left: 41.66667%
}
.o-layout__col_xs-offset_5 {
  margin-left: 41.66667%
}
.o-layout__col_xs_6 {
  width: 50%
}
.o-layout__col_xs-pull_6 {
  right: 50%
}
.o-layout__col_xs-push_6 {
  left: 50%
}
.o-layout__col_xs-offset_6 {
  margin-left: 50%
}
.o-layout__col_xs_7 {
  width: 58.33333%
}
.o-layout__col_xs-pull_7 {
  right: 58.33333%
}
.o-layout__col_xs-push_7 {
  left: 58.33333%
}
.o-layout__col_xs-offset_7 {
  margin-left: 58.33333%
}
.o-layout__col_xs_8 {
  width: 66.66666%
}
.o-layout__col_xs-pull_8 {
  right: 66.66666%
}
.o-layout__col_xs-push_8 {
  left: 66.66666%
}
.o-layout__col_xs-offset_8 {
  margin-left: 66.66666%
}
.o-layout__col_xs_9 {
  width: 75%
}
.o-layout__col_xs-pull_9 {
  right: 75%
}
.o-layout__col_xs-push_9 {
  left: 75%
}
.o-layout__col_xs-offset_9 {
  margin-left: 75%
}
.o-layout__col_xs_10 {
  width: 83.33333%
}
.o-layout__col_xs-pull_10 {
  right: 83.33333%
}
.o-layout__col_xs-push_10 {
  left: 83.33333%
}
.o-layout__col_xs-offset_10 {
  margin-left: 83.33333%
}
.o-layout__col_xs_11 {
  width: 91.66666%
}
.o-layout__col_xs-pull_11 {
  right: 91.66666%
}
.o-layout__col_xs-push_11 {
  left: 91.66666%
}
.o-layout__col_xs-offset_11 {
  margin-left: 91.66666%
}
.o-layout__col_xs_12 {
  width: 100%
}
.o-layout__col_xs-pull_12 {
  right: 100%
}
.o-layout__col_xs-push_12 {
  left: 100%
}
.o-layout__col_xs-offset_12 {
  margin-left: 100%
}
@media (min-width:481px) {
  .o-layout__col_sm_1 {
    width: 8.33333%
  }
  .o-layout__col_sm-pull_1 {
    right: 8.33333%
  }
  .o-layout__col_sm-push_1 {
    left: 8.33333%
  }
  .o-layout__col_sm-offset_1 {
    margin-left: 8.33333%
  }
  .o-layout__col_sm_2 {
    width: 16.66667%
  }
  .o-layout__col_sm-pull_2 {
    right: 16.66667%
  }
  .o-layout__col_sm-push_2 {
    left: 16.66667%
  }
  .o-layout__col_sm-offset_2 {
    margin-left: 16.66667%
  }
  .o-layout__col_sm_3 {
    width: 25%
  }
  .o-layout__col_sm-pull_3 {
    right: 25%
  }
  .o-layout__col_sm-push_3 {
    left: 25%
  }
  .o-layout__col_sm-offset_3 {
    margin-left: 25%
  }
  .o-layout__col_sm_4 {
    width: 33.33333%
  }
  .o-layout__col_sm-pull_4 {
    right: 33.33333%
  }
  .o-layout__col_sm-push_4 {
    left: 33.33333%
  }
  .o-layout__col_sm-offset_4 {
    margin-left: 33.33333%
  }
  .o-layout__col_sm_5 {
    width: 41.66667%
  }
  .o-layout__col_sm-pull_5 {
    right: 41.66667%
  }
  .o-layout__col_sm-push_5 {
    left: 41.66667%
  }
  .o-layout__col_sm-offset_5 {
    margin-left: 41.66667%
  }
  .o-layout__col_sm_6 {
    width: 50%
  }
  .o-layout__col_sm-pull_6 {
    right: 50%
  }
  .o-layout__col_sm-push_6 {
    left: 50%
  }
  .o-layout__col_sm-offset_6 {
    margin-left: 50%
  }
  .o-layout__col_sm_7 {
    width: 58.33333%
  }
  .o-layout__col_sm-pull_7 {
    right: 58.33333%
  }
  .o-layout__col_sm-push_7 {
    left: 58.33333%
  }
  .o-layout__col_sm-offset_7 {
    margin-left: 58.33333%
  }
  .o-layout__col_sm_8 {
    width: 66.66666%
  }
  .o-layout__col_sm-pull_8 {
    right: 66.66666%
  }
  .o-layout__col_sm-push_8 {
    left: 66.66666%
  }
  .o-layout__col_sm-offset_8 {
    margin-left: 66.66666%
  }
  .o-layout__col_sm_9 {
    width: 75%
  }
  .o-layout__col_sm-pull_9 {
    right: 75%
  }
  .o-layout__col_sm-push_9 {
    left: 75%
  }
  .o-layout__col_sm-offset_9 {
    margin-left: 75%
  }
  .o-layout__col_sm_10 {
    width: 83.33333%
  }
  .o-layout__col_sm-pull_10 {
    right: 83.33333%
  }
  .o-layout__col_sm-push_10 {
    left: 83.33333%
  }
  .o-layout__col_sm-offset_10 {
    margin-left: 83.33333%
  }
  .o-layout__col_sm_11 {
    width: 91.66666%
  }
  .o-layout__col_sm-pull_11 {
    right: 91.66666%
  }
  .o-layout__col_sm-push_11 {
    left: 91.66666%
  }
  .o-layout__col_sm-offset_11 {
    margin-left: 91.66666%
  }
  .o-layout__col_sm_12 {
    width: 100%
  }
  .o-layout__col_sm-pull_12 {
    right: 100%
  }
  .o-layout__col_sm-push_12 {
    left: 100%
  }
  .o-layout__col_sm-offset_12 {
    margin-left: 100%
  }
}
@media (min-width:768px) {
  .o-layout__col_md_1 {
    width: 8.33333%
  }
  .o-layout__col_md-pull_1 {
    right: 8.33333%
  }
  .o-layout__col_md-push_1 {
    left: 8.33333%
  }
  .o-layout__col_md-offset_1 {
    margin-left: 8.33333%
  }
  .o-layout__col_md_2 {
    width: 16.66667%
  }
  .o-layout__col_md-pull_2 {
    right: 16.66667%
  }
  .o-layout__col_md-push_2 {
    left: 16.66667%
  }
  .o-layout__col_md-offset_2 {
    margin-left: 16.66667%
  }
  .o-layout__col_md_3 {
    width: 25%
  }
  .o-layout__col_md-pull_3 {
    right: 25%
  }
  .o-layout__col_md-push_3 {
    left: 25%
  }
  .o-layout__col_md-offset_3 {
    margin-left: 25%
  }
  .o-layout__col_md_4 {
    width: 33.33333%
  }
  .o-layout__col_md-pull_4 {
    right: 33.33333%
  }
  .o-layout__col_md-push_4 {
    left: 33.33333%
  }
  .o-layout__col_md-offset_4 {
    margin-left: 33.33333%
  }
  .o-layout__col_md_5 {
    width: 41.66667%
  }
  .o-layout__col_md-pull_5 {
    right: 41.66667%
  }
  .o-layout__col_md-push_5 {
    left: 41.66667%
  }
  .o-layout__col_md-offset_5 {
    margin-left: 41.66667%
  }
  .o-layout__col_md_6 {
    width: 50%
  }
  .o-layout__col_md-pull_6 {
    right: 50%
  }
  .o-layout__col_md-push_6 {
    left: 50%
  }
  .o-layout__col_md-offset_6 {
    margin-left: 50%
  }
  .o-layout__col_md_7 {
    width: 58.33333%
  }
  .o-layout__col_md-pull_7 {
    right: 58.33333%
  }
  .o-layout__col_md-push_7 {
    left: 58.33333%
  }
  .o-layout__col_md-offset_7 {
    margin-left: 58.33333%
  }
  .o-layout__col_md_8 {
    width: 66.66666%
  }
  .o-layout__col_md-pull_8 {
    right: 66.66666%
  }
  .o-layout__col_md-push_8 {
    left: 66.66666%
  }
  .o-layout__col_md-offset_8 {
    margin-left: 66.66666%
  }
  .o-layout__col_md_9 {
    width: 75%
  }
  .o-layout__col_md-pull_9 {
    right: 75%
  }
  .o-layout__col_md-push_9 {
    left: 75%
  }
  .o-layout__col_md-offset_9 {
    margin-left: 75%
  }
  .o-layout__col_md_10 {
    width: 83.33333%
  }
  .o-layout__col_md-pull_10 {
    right: 83.33333%
  }
  .o-layout__col_md-push_10 {
    left: 83.33333%
  }
  .o-layout__col_md-offset_10 {
    margin-left: 83.33333%
  }
  .o-layout__col_md_11 {
    width: 91.66666%
  }
  .o-layout__col_md-pull_11 {
    right: 91.66666%
  }
  .o-layout__col_md-push_11 {
    left: 91.66666%
  }
  .o-layout__col_md-offset_11 {
    margin-left: 91.66666%
  }
  .o-layout__col_md_12 {
    width: 100%
  }
  .o-layout__col_md-pull_12 {
    right: 100%
  }
  .o-layout__col_md-push_12 {
    left: 100%
  }
  .o-layout__col_md-offset_12 {
    margin-left: 100%
  }
}
@media (min-width:992px) {
  .o-layout__col_lg_1 {
    width: 8.33333%
  }
  .o-layout__col_lg-pull_1 {
    right: 8.33333%
  }
  .o-layout__col_lg-push_1 {
    left: 8.33333%
  }
  .o-layout__col_lg-offset_1 {
    margin-left: 8.33333%
  }
  .o-layout__col_lg_2 {
    width: 16.66667%
  }
  .o-layout__col_lg-pull_2 {
    right: 16.66667%
  }
  .o-layout__col_lg-push_2 {
    left: 16.66667%
  }
  .o-layout__col_lg-offset_2 {
    margin-left: 16.66667%
  }
  .o-layout__col_lg_3 {
    width: 25%
  }
  .o-layout__col_lg-pull_3 {
    right: 25%
  }
  .o-layout__col_lg-push_3 {
    left: 25%
  }
  .o-layout__col_lg-offset_3 {
    margin-left: 25%
  }
  .o-layout__col_lg_4 {
    width: 33.33333%
  }
  .o-layout__col_lg-pull_4 {
    right: 33.33333%
  }
  .o-layout__col_lg-push_4 {
    left: 33.33333%
  }
  .o-layout__col_lg-offset_4 {
    margin-left: 33.33333%
  }
  .o-layout__col_lg_5 {
    width: 41.66667%
  }
  .o-layout__col_lg-pull_5 {
    right: 41.66667%
  }
  .o-layout__col_lg-push_5 {
    left: 41.66667%
  }
  .o-layout__col_lg-offset_5 {
    margin-left: 41.66667%
  }
  .o-layout__col_lg_6 {
    width: 50%
  }
  .o-layout__col_lg-pull_6 {
    right: 50%
  }
  .o-layout__col_lg-push_6 {
    left: 50%
  }
  .o-layout__col_lg-offset_6 {
    margin-left: 50%
  }
  .o-layout__col_lg_7 {
    width: 58.33333%
  }
  .o-layout__col_lg-pull_7 {
    right: 58.33333%
  }
  .o-layout__col_lg-push_7 {
    left: 58.33333%
  }
  .o-layout__col_lg-offset_7 {
    margin-left: 58.33333%
  }
  .o-layout__col_lg_8 {
    width: 66.66666%
  }
  .o-layout__col_lg-pull_8 {
    right: 66.66666%
  }
  .o-layout__col_lg-push_8 {
    left: 66.66666%
  }
  .o-layout__col_lg-offset_8 {
    margin-left: 66.66666%
  }
  .o-layout__col_lg_9 {
    width: 75%
  }
  .o-layout__col_lg-pull_9 {
    right: 75%
  }
  .o-layout__col_lg-push_9 {
    left: 75%
  }
  .o-layout__col_lg-offset_9 {
    margin-left: 75%
  }
  .o-layout__col_lg_10 {
    width: 83.33333%
  }
  .o-layout__col_lg-pull_10 {
    right: 83.33333%
  }
  .o-layout__col_lg-push_10 {
    left: 83.33333%
  }
  .o-layout__col_lg-offset_10 {
    margin-left: 83.33333%
  }
  .o-layout__col_lg_11 {
    width: 91.66666%
  }
  .o-layout__col_lg-pull_11 {
    right: 91.66666%
  }
  .o-layout__col_lg-push_11 {
    left: 91.66666%
  }
  .o-layout__col_lg-offset_11 {
    margin-left: 91.66666%
  }
  .o-layout__col_lg_12 {
    width: 100%
  }
  .o-layout__col_lg-pull_12 {
    right: 100%
  }
  .o-layout__col_lg-push_12 {
    left: 100%
  }
  .o-layout__col_lg-offset_12 {
    margin-left: 100%
  }
}
@media (min-width:1240px) {
  .o-layout__col_xl_1 {
    width: 8.33333%
  }
  .o-layout__col_xl-pull_1 {
    right: 8.33333%
  }
  .o-layout__col_xl-push_1 {
    left: 8.33333%
  }
  .o-layout__col_xl-offset_1 {
    margin-left: 8.33333%
  }
  .o-layout__col_xl_2 {
    width: 16.66667%
  }
  .o-layout__col_xl-pull_2 {
    right: 16.66667%
  }
  .o-layout__col_xl-push_2 {
    left: 16.66667%
  }
  .o-layout__col_xl-offset_2 {
    margin-left: 16.66667%
  }
  .o-layout__col_xl_3 {
    width: 25%
  }
  .o-layout__col_xl-pull_3 {
    right: 25%
  }
  .o-layout__col_xl-push_3 {
    left: 25%
  }
  .o-layout__col_xl-offset_3 {
    margin-left: 25%
  }
  .o-layout__col_xl_4 {
    width: 33.33333%
  }
  .o-layout__col_xl-pull_4 {
    right: 33.33333%
  }
  .o-layout__col_xl-push_4 {
    left: 33.33333%
  }
  .o-layout__col_xl-offset_4 {
    margin-left: 33.33333%
  }
  .o-layout__col_xl_5 {
    width: 41.66667%
  }
  .o-layout__col_xl-pull_5 {
    right: 41.66667%
  }
  .o-layout__col_xl-push_5 {
    left: 41.66667%
  }
  .o-layout__col_xl-offset_5 {
    margin-left: 41.66667%
  }
  .o-layout__col_xl_6 {
    width: 50%
  }
  .o-layout__col_xl-pull_6 {
    right: 50%
  }
  .o-layout__col_xl-push_6 {
    left: 50%
  }
  .o-layout__col_xl-offset_6 {
    margin-left: 50%
  }
  .o-layout__col_xl_7 {
    width: 58.33333%
  }
  .o-layout__col_xl-pull_7 {
    right: 58.33333%
  }
  .o-layout__col_xl-push_7 {
    left: 58.33333%
  }
  .o-layout__col_xl-offset_7 {
    margin-left: 58.33333%
  }
  .o-layout__col_xl_8 {
    width: 66.66666%
  }
  .o-layout__col_xl-pull_8 {
    right: 66.66666%
  }
  .o-layout__col_xl-push_8 {
    left: 66.66666%
  }
  .o-layout__col_xl-offset_8 {
    margin-left: 66.66666%
  }
  .o-layout__col_xl_9 {
    width: 75%
  }
  .o-layout__col_xl-pull_9 {
    right: 75%
  }
  .o-layout__col_xl-push_9 {
    left: 75%
  }
  .o-layout__col_xl-offset_9 {
    margin-left: 75%
  }
  .o-layout__col_xl_10 {
    width: 83.33333%
  }
  .o-layout__col_xl-pull_10 {
    right: 83.33333%
  }
  .o-layout__col_xl-push_10 {
    left: 83.33333%
  }
  .o-layout__col_xl-offset_10 {
    margin-left: 83.33333%
  }
  .o-layout__col_xl_11 {
    width: 91.66666%
  }
  .o-layout__col_xl-pull_11 {
    right: 91.66666%
  }
  .o-layout__col_xl-push_11 {
    left: 91.66666%
  }
  .o-layout__col_xl-offset_11 {
    margin-left: 91.66666%
  }
  .o-layout__col_xl_12 {
    width: 100%
  }
  .o-layout__col_xl-pull_12 {
    right: 100%
  }
  .o-layout__col_xl-push_12 {
    left: 100%
  }
  .o-layout__col_xl-offset_12 {
    margin-left: 100%
  }
}
.c-breadcrumbs {
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: 14px;
  color: #520dd5;
  padding-top: 10px;
  padding-bottom: 10px
}
.c-breadcrumbs__item {
  display: inline-block;
  padding-left: 9px;
  padding-right: 9px;
  border-right: 1px solid #5f5f5f;
  line-height: 1em;
  margin-left: -0.25em
}
.c-breadcrumbs__item:first-child {
  padding-left: 0
}
.c-breadcrumbs__item:last-child {
  border: 0
}
.c-breadcrumbs__link {
  color: #5f5f5f;
  text-decoration: none
}
.c-breadcrumbs__link:hover {
  text-decoration: underline
}
.c-breadcrumbs-wrapper {
  background: #e5e5e5
}
.c-btn {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #520dd5;
  border-radius: 5px;
  background-image: -webkit-linear-gradient( 45deg, rgb(81, 11, 212) 0%, rgb(103, 39, 223) 100%);
  background-image: linear-gradient( 45deg, rgb(81, 11, 212) 0%, rgb(103, 39, 223) 100%);
  min-width: 120px;
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  border: 0;
  text-transform: uppercase
}
.c-btn:hover {
  background-image: -webkit-linear-gradient( 65deg, rgb(205, 142, 30) 0%, rgb(208, 147, 1) 100%)
}
.c-btn_outline {
  background: transparent;
  border: 1px solid #b8b8b8;
  color: #b8b8b8;
  cursor: pointer;
  text-transform: none;
  font-size: 15px;
  letter-spacing: 0.075em;
  min-width: 165px;
  -webkit-transition: color 300ms, border 300ms;
  transition: color 300ms, border 300ms
}
.c-btn_outline:hover {
  background: transparent;
  color: #520dd5;
  border-color: #520dd5
}
.c-btn_outline.is-hover {
  background: transparent;
  color: #520dd5;
  border-color: #520dd5
}
.c-btn_full {
  width: 100%
}
.c-btn-categories {
  display: none;
  margin-bottom: 20px;
  outline: none
}
@media (max-width:767px) {
  .c-btn-categories {
    display: block
  }
}
.c-card {
  background: #fff;
  border-width: 1px;
  border-color: rgb(232, 232, 232);
  border-style: solid;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding: 23px 20px;
  text-align: center;
  margin-bottom: 15px
}
@media (max-width:991px) {
  .c-card {
    padding-left: 15px;
    padding-right: 15px
  }
}
.c-card__title {
  color: #262728;
  text-transform: uppercase;
  margin-bottom: 10px
}
.c-card__pic {
  display: inline-block
}
.c-card__desc {
  font-size: 13px;
  color: #6b6b6b
}
.c-card__footer:after {
  content: '';
  display: block;
  clear: both
}
.c-card__footer {
  text-align: left;
  margin-top: 25px
}
.c-card__footer-part {
  float: left
}
@media (max-width: 530px) {
  .c-card__footer-part {
    float: none
  }
}
.c-card__footer-part_right {
  float: right
}
@media (max-width: 530px) {
  .c-card__footer-part_right {
    float: none
  }
}
.c-card__price {
  font-size: 15px;
  color: #262728
}
@media (max-width: 530px) {
  .c-card__price {
    text-align: center
  }
}
.c-card__price-label {
  font-size: 14px;
  margin-bottom: 3px
}
.c-card__price b {
  font-size: 20px
}
@media (max-width: 530px) {
  .c-card .c-btn {
    width: 100%;
    margin-top: 10px
  }
}
.c-header-btn {
  display: block;
  width: 30px;
  height: 30px;
  float: left;
  position: relative
}
.c-header-btn:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms
}
.c-header-btn_masonry:before {
  background-image: url('../img/masonry.png')
}
.c-header-btn_list:before {
  background-image: url('../img/list.png')
}
.c-header-btn.is-active {
  background: #b0b0b0
}
.c-header-btn.is-active:before {
  opacity: 1
}
.c-header-btn:hover:before {
  opacity: 1
}
.c-header-option:after {
  content: '';
  display: block;
  clear: both
}
.c-header-option {
  display: inline-block;
  vertical-align: middle;
  font-family: 'PFAgoraSansPro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.05em
}
@media (max-width:767px) {
  .c-header-option {
    padding-top: 5px
  }
}
.c-header-option__label {
  display: inline-block;
  margin-right: 10px
}
@media (max-width:480px) {
  .c-header-option__label {
    display: none
  }
}
.c-header-option .c-select {
  display: inline-block;
  vertical-align: middle
}
@media (max-width:767px) {
  .c-header-option_btns {
    float: right;
    padding-top: 0
  }
}
.c-info {
  padding: 45px 0
}
.c-info-pics {
  position: relative;
  width: 520px;
  height: 430px
}
@media (max-width:991px) {
  .c-info-pics {
    margin: auto
  }
}
@media (max-width: 560px) {
  .c-info-pics {
    width: 280px;
    height: auto
  }
}
@media (max-width:480px) {
  .c-info-pics {
    width: 240px
  }
}
@media (max-width: 560px) {
  .c-info-pics__item {
    margin-bottom: 20px
  }
}
.c-info-pics__item-pic {
  border-radius: 5px;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, .19)
}
.c-info-pics__item-caption {
  width: 220px;
  font-size: 15px;
  font-weight: 300;
  text-align: right;
  margin-top: 20px;
  letter-spacing: 0.075em
}
@media (max-width: 560px) {
  .c-info-pics__item-caption {
    width: auto;
    text-align: center
  }
}
.c-info-pics__item-caption span {
  font-size: 18px;
  font-weight: 400;
  display: block
}
.c-info-pics__item-caption_left {
  text-align: left;
  margin-left: 55px;
  margin-top: 0;
  margin-bottom: 20px
}
@media (max-width: 560px) {
  .c-info-pics__item-caption_left {
    margin-left: 0;
    text-align: center
  }
}
.c-info-pics__item_abs {
  position: absolute;
  left: 230px;
  top: 25px
}
@media (max-width: 560px) {
  .c-info-pics__item_abs {
    position: static
  }
}
.o-page-header:after {
  content: '';
  display: block;
  clear: both
}
.o-page-header {
  padding-top: 50px
}
@media (max-width:767px) {
  .o-page-header {
    padding-top: 20px
  }
}
@media (max-width:767px) {
  .o-page-header {
    margin-bottom: 20px
  }
}
.o-page-header__part:after {
  content: '';
  display: block;
  clear: both
}
.o-page-header__part {
  float: left
}
@media (max-width:767px) {
  .o-page-header__part {
    float: none
  }
}
.o-page-header__part_right {
  float: right
}
@media (max-width:767px) {
  .o-page-header__part_right {
    float: none
  }
}
@media (max-width:767px) {
  .o-page-header .title {
    margin-bottom: 10px
  }
}
.o-page-side {
  display: table-cell;
  vertical-align: top;
  width: 1%
}
@media (max-width:767px) {
  .o-page-side {
    width: auto;
    display: block
  }
}
.o-page-main {
  display: table-cell;
  vertical-align: top;
  padding-left: 50px
}
@media (max-width:991px) {
  .o-page-main {
    padding-left: 20px
  }
}
@media (max-width:767px) {
  .o-page-main {
    padding-left: 0;
    display: block
  }
}
.c-select {
  position: relative;
  width: 156px;
  font-size: 12px;
  z-index: 20
}
.c-select__current {
  display: inline-block;
  color: #520dd5;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  padding: 0;
  border-bottom: 1px dotted #520dd5;
  position: relative;
  outline: none
}
.c-select__current:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -2px;
  background: url('../img/arrow-down.png') no-repeat;
  width: 7px;
  height: 5px;
  margin-left: 3px
}
.c-select-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, .51);
  padding: 15px 10px 15px 20px;
  -webkit-transform: translateY(6px);
          transform: translateY(6px);
  display: none
}
.c-select-list__item {
  line-height: 1.334em
}
.c-select-list__item.is-active .c-select-list__btn {
  color: #520dd5
}
.c-select-list__item.is-active .c-select-list__btn:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 50%;
  margin-right: 4px;
  margin-top: -4px;
  background: url('../img/true.png') no-repeat;
  width: 9px;
  height: 7px
}
.c-select-list__btn {
  padding: 0;
  background-color: transparent;
  border: 0;
  text-align: left;
  cursor: pointer;
  position: relative;
  -webkit-transition: color 300ms;
  transition: color 300ms
}
.c-select-list__btn:hover {
  color: #520dd5
}
.c-seo {
  font-size: 17px;
  color: #484646;
  line-height: 1.412em;
  margin-top: 60px;
  margin-bottom: 30px
}
@media (max-width:767px) {
  .c-seo {
    margin-top: 30px;
    font-size: 14px
  }
}
.c-seo__title {
  font-size: 24px;
  letter-spacing: 0.075em;
  color: #000
}
@media (max-width:767px) {
  .c-seo__title {
    font-size: 20px
  }
}
.c-seo__title_lg {
  font-size: 28px;
  line-height: 1.2em
}
.c-seo__content {
  font-weight: 300
}
.c-seo_margin-none {
  margin-top: 0;
  padding-right: 80px
}
@media (max-width:767px) {
  .c-seo_margin-none {
    padding-right: 0
  }
}
.c-sidebar {
  width: 290px;
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: 17px
}
@media (min-width:768px) {
  .c-sidebar {
    display: block !important
  }
}
@media (max-width:991px) {
  .c-sidebar {
    width: 190px;
    font-size: 15px
  }
}
@media (max-width:767px) {
  .c-sidebar {
    width: 100%;
    display: none
  }
}
.c-sidebar__item {
  margin-bottom: 4px
}
.c-sidebar__item.is-active .c-sidebar__link {
  background-image: -webkit-linear-gradient( 45deg, rgb(81, 11, 212) 0%, rgb(103, 39, 223) 100%);
  background-image: linear-gradient( 45deg, rgb(81, 11, 212) 0%, rgb(103, 39, 223) 100%);
  border-radius: 3px;
  color: #fff
}
.c-sidebar__item.is-active .c-sidebar__link:hover {
  color: #fff
}
.c-sidebar__link {
  text-decoration: none;
  color: inherit;
  line-height: 1.118em;
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: color 300ms;
  transition: color 300ms;
  display: block
}
@media (max-width:991px) {
  .c-sidebar__link {
    padding-left: 10px;
    padding-right: 10px
  }
}
.c-sidebar__link:hover {
  color: #520dd5
}
.c-slider {
  margin-top: 2.188em;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, .19)
}
@media (max-width:480px) {
  .c-slider {
    display: none
  }
}
.c-slider__item {
  outline: none;
  border-radius: 10px
}
.c-slider__item-pic {
  display: block
}
.slick-dots {
  position: absolute;
  left: 25px;
  right: 25px;
  bottom: 25px;
  text-align: right;
  list-style: none;
  padding-left: 0;
  margin: 0
}
.slick-dots li {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px
}
.slick-dots li.slick-active button {
  background-color: #9d6809;
  border-color: #9d6809
}
.slick-dots button {
  display: block;
  outline: none;
  font-size: 0;
  border: 1px solid #969696;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  -webkit-transition: border 300ms;
  transition: border 300ms
}
.slick-dots button:hover {
  border-color: #9d6809
}
.title {
  font-size: 28px;
  letter-spacing: 0.075em;
  font-weight: 400;
  margin: 0;
  margin-bottom: 30px
}
.u-align-center {
  text-align: center
}