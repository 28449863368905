.social{
	margin: 20px 0 0;
	display: inline-block;
	@media (max-width: 767px){
		display: block;
	}
	@nest &__desc{
		font-size: 16px;
		color: #1c1b1b;
		margin-right: 25px;
		font-weight: 500;
		letter-spacing: 1.125px;
		display: inline-block;
		vertical-align: middle;
		@media (max-width: 767px){
			display: block;
			margin-bottom: 10px;
			margin-right: 0;
		}
	}
	@nest &__link{
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
		transition: opacity 300ms;
		@nest &:hover{
			opacity: .7;
		}
	}
}