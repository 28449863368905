/* nav-toggle */
.nav-toggle{
	text-align: center;
	padding: 10px 0;
	@media (min-width: 767px){
		display: none;
	}
	@nest &__title{
		font-size: 16px;
		color: #000;
		margin-right: 10px;
		margin-bottom: 0;
		display: inline-block;
		vertical-align: middle;
	}
	@nest &__btn{
		border: 0;
		outline: none;
		width: 31px;
		padding: 0;
		margin-top: 3px;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		background-color: transparent;
	}
	@nest &__line{
		display: block;
		width: 100%;
		height: 6px;
		background: #000;
		margin-bottom: 2px;
		-webkit-transition: all 300ms;
		transition: all 300ms;
		border-radius: 7px;
	}
	@nest &--open{
		@nest & .nav-toggle__line{
			@nest &:first-child{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				transform: rotate(-45deg);
			}
			@nest &:nth-child(2){
				display: none;
			}
			@nest &:nth-child(3){
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				transform: rotate(45deg);
			}
		}
	}
}