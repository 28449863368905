.services{
	@nest &__content{
		font-size: 0;
	}
	@nest &__blocks{
		width: 68%;
		margin-right: 4%;
		display: inline-block;
		vertical-align: top;
		@media (max-width: 1199px){
			width: 60%;
			margin-right: 2%;
		}
		@media (max-width: 991px){
			width: 100%;
			margin: 0;
			text-align: center;
		}
	}
	@nest &__wrap{
		margin: 0 -10px;
		@media (max-width: 767px){
			margin: 0;
		}
	}
}