.serv-nav{
	margin: 0;
	width: 28%;
	list-style: none;
	min-height: 520px;
	padding: 35px 0 0 25px;
	border-radius: 5px;
	display: inline-block;
	vertical-align: top;
	background: #fff resolve('serv-nav.jpg') no-repeat right bottom;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.19);
  @media (max-width: 1199px){
  	width: 38%;
  }
  @media (max-width: 991px){
  	width: 100%;
  	max-width: 335px;
  	margin: auto;
  	display: block;
  }
	@nest &__link{
		font-size: 16px;
		font-weight: 300;
		color: #000;
		margin: 0 0 25px;
		display: block;
		letter-spacing: -.16px;
		text-decoration: none;
		@nest &:hover{
			font-weight: 500;
			color: #570bd4;
			padding-left: 8px;
		}
	}
}