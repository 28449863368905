.nav{
	background: #fff;
	padding: 20px 0;
	@nest &__wrap{
		float: right;
		position: relative;
		min-width: 1px;
		min-height: 21px;
	}
	@nest &__btn{
		position: absolute;
		content: '';
		top: 50%;
		border: 0;
		width: 21px;
		height: 21px;
		padding: 0;
		outline: none;
		z-index: 10;
		right: 5px;
		margin-top: -10.5px;
		background: transparent;
		transition: opacity 300ms;
		@nest &:hover{
			opacity: .7;
		}
		@nest &--hid{
			display: none;
		}
		@media (max-width: 767px){
			float: none;
		}
	}
}