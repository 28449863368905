/*! global */
html {
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
body{
  font-size: 1rem;
  line-height: 1.4;
  color: #373a3c;
}
*,*:before,*:after{
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
address,dl,ol,p,ul {
  margin-bottom: 1rem;
}
dl,ol,pre,ul {
  margin-top: 0;
}

h1,h2,h3,h4,h5,h6{
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 0;
  margin-bottom: .5rem;
}
p{
  margin-bottom: 1rem;
}
h1{
  font-size: 2.5rem;
}
h2{
  font-size: 2rem;
}
h3{
  font-size: 1.75rem;
}
h4{
  font-size: 1.5rem;
}
h5{
  font-size: 1.25rem;
}
h6{
  font-size: 1rem;
}
img{
  max-width: 100%;
}