.footer{
	background: #ececec;
	padding: 27px 0 30px;
	border-bottom: 5px solid #c7c7c7;
	@media (max-width: 991px){
		text-align: center;
	}
	@nest &__text{
		float: left;
		margin: 20px 0 0;
		text-align: left;
		@media (max-width: 512px){
			float: none;
			display: block;
			text-align: center;
		}
		@nest &--right{
			float: right;
			text-align: right;
			@media (max-width: 512px){
				float: none;
				text-align: center;
			}
		}
		@nest &-desc{
			font-size: 15px;
			display: block;
			color: #000;
			font-weight: 300;
			letter-spacing: .75px;
			@media (max-width: 1199px){
				font-size: 14px;
			}
		}
		@nest &-link{
			font-size: 15px;
			color: #000;
			font-weight: 300;
			text-decoration: none;
			letter-spacing: .75px;
			text-transform: uppercase;
			@nest &:hover{
				text-decoration: underline;
			}
			@media (max-width: 1199px){
				font-size: 14px;
			}
		}
	}
}